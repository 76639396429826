/* style.component.css */

/* Hide text-based navigation on small screens */
@media (max-width: 768px) {
    .text-menu {
      display: none;
    }
  }
  
  /* Hide menu icon on larger screens */
  @media (min-width: 769px) {
    .md\:hidden {
      display: none !important;
    }
  }
  


  /********************** SLider *****************************/

  .hero {
    position: relative;
    width: 100%;
  }
  
  .slide-content {
    position: relative;
    height: 80vh;
  }
  
  .slide-content img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .overlays {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    line-height: 2em;
    background-color: #0b0c1047;
    width: 100%;
    height:101vh;
  }
  .overlays-content{
    position: relative;
    padding: 20px;
    left: 5%;
    top: 40%;
  }
  
  .overlays h2 {
    font-size: 3rem;
    margin-bottom: 5px;
    color: #ffcc00;
    text-transform: uppercase;
    text-shadow: 0px 20px 8px rgba(0, 0, 0, 0.4);
  }
  
  .overlays p {
    font-size: 26px;
    /* margin-bottom: 20px; */
  }

  .iconSlider{
    width: fit-content;
    height: auto;
    background-color: #eaf9ff;
    justify-content: space-between;
    padding: 5px;
    border-radius: 10px ;
  }
  .iconSlider a {
    display: inline-block;
    width: 35px;
    padding: 2px;
    cursor: pointer;
    color: #053576;
    /* background-color: #090b13; */
    border-radius: 2px;
    font-size: 25px;
    text-align: center;
  }
  .overlays-content .btn{
    padding: 1em 3.5em;
  }
   .iconSlider  :hover {
    color: #eaf9ff;
    background-color: #053576;
    /* -webkit-animation: vibrate-1 0.3s linear infinite both;
    animation: vibrate-1 0.3s linear infinite both; */
  }
  
    .flicker-1 {
      -webkit-animation: flicker-1 2s linear infinite both;
              animation: flicker-1 2s linear infinite both;
    }
    @-webkit-keyframes flicker-1 {
      0%,
      100% {
        opacity: 1;
      }
      41.99% {
        opacity: 1;
      }
      42% {
        opacity: 0;
      }
      43% {
        opacity: 0;
      }
      43.01% {
        opacity: 1;
      }
      47.99% {
        opacity: 1;
      }
      48% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      49.01% {
        opacity: 1;
      }
    }
    @keyframes flicker-1 {
      0%,
      100% {
        opacity: 1;
      }
      41.99% {
        opacity: 1;
      }
      42% {
        opacity: 0;
      }
      43% {
        opacity: 0;
      }
      43.01% {
        opacity: 1;
      }
      47.99% {
        opacity: 1;
      }
      48% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      49.01% {
        opacity: 1;
      }
    }
   
    
    
  @media screen and (max-width: 768px) {
    .margin {
      height: 50vh;
    }
    .hero {
      background-position: center;
      /* padding-top: 20%; */
  
    }
    .hero .row {
      width: 100%;
    }
    .homeAbout{
      margin-top:575px ;
      }
      .slide-content{
       object-fit: cover;
      }
      .slide-content img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .iconSlider{
        margin-left: 35%;
        display: none;
      }
      .iconSlider a{
        font-size: 15px;
        width: 25px;
        height: 25px;
      }
     
      .overlays {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        background-color: #0b0c107e;
        width: 100%;
        height:100%;
      }
      .overlays-content{
        position: absolute;
        top: 50%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
      .overlays h2 {
        font-size: 2rem;
        margin-bottom: 10px;
        color: #ffcc00;
        text-transform: uppercase;
        text-shadow: 0px 20px 8px rgba(0, 0, 0, 0.4);
      }
      
      .overlays p {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.2;
      }
  }
  





  /****************** Navigation bar ********************************** */

  .menu ul{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .menu li{
    display: inline-block;
    margin: 0 15px;
  }
  .menu a{
    /* font-size: 22px; */
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    display: block;
    position: relative;
    padding: 4px 0;
    cursor: pointer;
  }
  .menu a::before{
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    transition: 0.5s transform ease;
    transform: scale3d(0,1,1);
    transform-origin: 0 50%;
  }
  .menu a:hover::before{
    transform: scale3d(1,1,1);
  }

  .menu-5 a::before{
    background: #C5C6C7;
    transform-origin: 0 50%;
  }
  .menu-5 a:hover::before{
    transform-origin: 100% 50%;
  }