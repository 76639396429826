.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;
}

.modal-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}



.category-list {
display: grid;
 grid-template-columns: repeat(4, minmax(0, 1fr));
gap: 25px;
margin: 5rem;
}

.category-item {
border: 1px solid #ccc;
padding: 10px;
text-align: center;
width: 200px;
}

.category-item h2 {
font-size: 18px;
}

.category-item button {
margin-top: 10px;
padding: 5px 10px;
}


.ReactModalPortal .ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.383) !important;
}
 .ReactModal__Content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none!important;
  outline: none;
}

.card {
border: solid 4px #053576;
box-shadow: -5px 5px #053576;
transition: all .2s ease-in-out;
}

.card:hover {
margin: 10px 10px 0 0;
box-shadow: 0px 0px rgba(51, 51, 51, 0.755);
}

.card .info-card {
position: absolute;
height: 50px;
width: 100%;
bottom: 0;
left: 0;
right: 0;
border-top: solid 1px #333;
}

.card .info-card p {
text-align: center;
font-size: x-large;
margin: 0%;
padding: 10px ;
}

  @media screen and (max-width: 768px) {
    .category-list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding: 30px 20px;
      margin: 0;
    }
  }
  
    /* Styles for the modal overlay */
 
  
  