@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}


.btn {
  --color: #ffcc00;
  --color2: rgb(10, 25, 30);
  padding: 0.4em 1.75em;
  background-color: transparent;
  border-radius: 12px;
  border: .3px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 700;
  font-size: auto;
  text-transform: uppercase;
  color: var(--color);
 }
 
 .btn::after, .btn::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
 }
 
 .btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
 }
 
 .btn:hover {
  color: var(--color2);
 }
 
 .btn:active {
  filter: brightness(.7);
  transform: scale(.98);
 }
.btn1 {
  --color: #004bab;
  --color2: #ffffff;
  padding: 0.4em 1.75em;
  background-color: transparent;
  border-radius: 12px;
  border: .3px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: auto;
  text-transform: uppercase;
  color: var(--color);
 }
 
 .btn1::after, .btn1::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
 }
 
 .btn1::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn1:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn1:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
 }
 
 .btn1:hover {
  color: var(--color2);
 }
 
 .btn1:active {
  filter: brightness(.7);
  transform: scale(.98);
 }


 @media screen and (max-width: 650px) {
  .btn1{
    font-size: 10px;
   }
  .btn{
    font-size: 10px;
   }
 }